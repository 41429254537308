import Vue from 'vue/dist/vue.esm'
import template from './staff_members_template.slim'
import { VueGoodTable } from 'vue-good-table'

Vue.component('staff-list', {
  mixins: [template],
  components: {
    VueGoodTable,
  },
  props: ['staff_member_rows', 'teacher_learning','classrooms_label'],
  mounted() {
    $(document).on('click', '.resend-button', function(e) {
      e.preventDefault()
      e.stopPropagation()
      const button = $(this)
      $.ajax({
        url: button.attr('href'),
        type: 'post',
        contentType: 'application/json',
        dataType: 'json',
      })
        .done(function() {
          button.after('<span class="block">Invitation Sent</span>')
          button.remove()
        })
        .fail(function() {
          button.after('<span class="block">Unable to resend invitation</span>')
        })
    })
  },
  data() {
    return {
      columnsForStaffMembers: [
        {
          label: 'Name',
          field: 'name',
          sortable: true,
          sortFn: this.sortFn,
          html: true,
          tdClass: 'staff-name',
          tdClass: 'bold-link',
        },
        {
          label: 'Role',
          field: 'role',
        },
        {
          label: `${this.classrooms_label} As Observer`,
          field: 'classroomAsObserver',
        },
        {
          label: `${this.classrooms_label} As Learner`,
          field: 'classroomsAsLearner',
          hidden: !this.teacher_learning,
        },
        {
          label: 'Subjects',
          field: 'subjects',
        },
        {
          label: 'Grades',
          field: 'grades',
        },
        {
          label: 'Last Activity',
          field: 'lastActivity',
        },
        {
          label: '',
          field: 'actions',
          sortable: true,
          sortFn: this.sortFn,
          html: true,
          tdClass: 'resend-invite-button',
          tdClass: 'action-button',
        },
      ],
    }
  },
  methods: {
    sortFn(x, y) {
      x = x.replace(/<\/?a[^>]*>/g, '')
      y = y.replace(/<\/?a[^>]*>/g, '')
      return x < y ? -1 : x > y ? 1 : 0
    },
    deactivateSelectedStaffMembers() {
      const selectedRows = this.$refs['staff-table'].selectedRows;
      const confirmation = confirm('Are you sure you want to deactivate the selected staff members?');
      
      if (confirmation) {
        const staffIds = selectedRows.map(row => row.id);
      
        $.ajax({
          url: `/go/${this.$schoolSlug}/staff/batch_deactivate`,
          method: 'POST',
          data: { staff_ids: staffIds },
        })
      };
    }
  }
})
