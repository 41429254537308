import Vue from "vue/dist/vue.esm";
import template from './active_goals_report_template.slim';
import { VueGoodTable } from 'vue-good-table';

Vue.component('active-goals-report', {
  mixins: [template],
  components: {
    VueGoodTable,
  },
  props: ['mastery_objective_rows', 'show_objectives_table', 'learner_active_goal_rows', 'learner_roles'],
  data() {
    const filtersData = JSON.parse(sessionStorage.getItem('activeGoalReportsFiltersData'));
    const schoolSlug = $('#app').data('schoolSlug');
    return {
      schoolSlug: $('#app').data('schoolSlug'),
      showObjectivesTable: true,
      showRoleModal: false,
      selectedRoles: filtersData && filtersData[schoolSlug] ? filtersData[schoolSlug]?.roles : [],
      roleOptions: this.learner_roles,
      columnsForObjectives: [
        {
          label: 'Mastery Component / Mastery Objective',
          field: 'masteryObjective',
          tdClass: 'mastery-objective-td',
          sortable: true,
          sortFn: this.sortFn,
          html: true,
        },
        {
          label: 'Teachers w/ Objective + Current Active Goal',
          field: 'count',
          type: 'number',
          tdClass: 'mastery-objective-count',
          thClass: 'mastery-objective-count',
        },
      ],
      columnsForLearners: [
        {
          label: 'Name',
          field: 'name',
          tdClass: 'name-td',
        },
        {
          label: 'Objectives Tied to Active Goal',
          field: 'objectives',
          tdClass: 'objectives-td',
          width: '400px'
        },
        {
          label: 'Active Goal Text',
          field: 'activeGoal',
          tdClass: 'active-goal-td',
          html: true
        },
      ],
      learnerActiveGoalRows: this.learner_active_goal_rows,
      masteryObjectiveRows: this.mastery_objective_rows,
    };
  },
  computed: {
    availableRoleOptions() {
      return this.roleOptions.filter(role => !this.selectedRoles.includes(role));
    },
    activeGoalReportsFiltersData() {
      return JSON.parse(sessionStorage.getItem('activeGoalReportsFiltersData')) || {}
    }
  },
  mounted() {
    if(this.show_objectives_table === false){
      document.getElementById('learnersRadio').click();
    }

    document.addEventListener('click', this.handleOutsideClick)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleOutsideClick);
  },
  methods: {
    handleOutsideClick(event) {
      const modal = this.$refs.roleDropDownButton

      if (modal && !modal.contains(event.target)) {
        this.showRoleModal = false;
      }
    },
    sortFn(x, y) {
      x = x.replace(/<\/?a[^>]*>/g, '');
      y = y.replace(/<\/?a[^>]*>/g, '');
      return x < y ? -1 : x > y ? 1 : 0;
    },
    toggleObjectivesTable() {
      this.$children[0].sorts = [];
      this.showObjectivesTable = true;
      this.showRoleModal = false;
    },
    toggleLearnersTable() {
      this.$children[0].sorts = [];
      this.showObjectivesTable = false;
      this.showRoleModal = false;
    },
    toggleRoleModal() {
      this.showRoleModal = !this.showRoleModal;
    },
    selectRole(roleValue) {
      if (this.isRoleSelected(roleValue)) {
        this.selectedRoles = this.selectedRoles.filter(role => role !== roleValue);
      } else {
        this.selectedRoles.push(roleValue);
        this.toggleRoleModal();
      }

      //update session storage with updated filters
      this.activeGoalReportsFiltersData[this.schoolSlug] = {
        'roles': this.selectedRoles
      };
      sessionStorage.setItem('activeGoalReportsFiltersData', JSON.stringify(this.activeGoalReportsFiltersData));
      this.applyFilters();
    },
    isRoleSelected(roleValue) {
      return this.selectedRoles.includes(roleValue);
    },
    resetRoleFilter() {
      this.selectedRoles = [];
      sessionStorage.setItem('activeGoalReportsFiltersData', JSON.stringify({}));
      this.applyFilters();
    },
    applyFilters() {
      $.ajax({
        url: window.location.href,
        method: 'GET',
        data: {
          roles: this.selectedRoles.map(role => role.toLowerCase())
        },
        dataType: 'json',
        success: (data) => {
          this.masteryObjectiveRows = data.mastery_objective_rows;
          this.learnerActiveGoalRows = data.learner_active_goal_rows;
        },
        error: (xhr, status, error) => {
          console.error('Error fetching data:', error);
        }
      });
    }
  },
});
