import Vue from 'vue/dist/vue.esm'
import './components/session_template_card_panel'
import template from './session_templates_template.slim'
import axios from 'axios'

Vue.component('session-templates', {
  mixins: [template],
  props: [
    'school_slug',
    'session_templates',
    'session_options',
    'current_staff_name',
  ],
  data() {
    return {
      sessionTemplates: this.session_templates,
    }
  },
  methods: {
    onSelectOption(option) {
      console.log(`User Selected Option: ` + option)
    },
    toggleFavorite(session_template) {
      axios
        .patch(
          `/go/${this.school_slug}/v2/session_templates/toggle_favorite/${
            session_template.id
          }`
        )
        .catch(error => {
          console.log(error)
          alert('oops, something went wrong!')
        })
    },
  },
})
