import Vue from 'vue/dist/vue.esm'
import template from './session_template_card_template.slim'

Vue.component('session-template-card', {
  props: ['session_template', 'school_slug', 'toggle_favorite'],
  mixins: [template],
  data() {
    return {
      favorited: this.session_template.favorited,
    }
  },
  methods: {
    toggleFavorite() {
      this.favorited = !this.favorited
      this.toggle_favorite()
    },
    goToDetail(e) {
      if (!e.target.classList.contains('favorite-icon')) {
        window.location = this.session_template.detail_path
      }
    },
  },
})
