import Vue from "vue/dist/vue.esm";
import template from "./session_template_template.slim";
import SessionTemplatesService from "../../services/session_templates_service.js";

Vue.component("session-template", {
  props: [
    "session_template",
    "school_slug",
    "session_options",
    "current_staff_name",
    "index_path"
  ],
  mixins: [template],
  data() {
    return {
      sessionTemplateState: this.session_template,
      error: null,
      sessionTemplateForm: this.initializeSessionTemplateForm(
        this.session_template
      ),
      learnersOptions: [],
      default_assessor: this.session_options.current_staff,
      addWholeClassDisabled: true,
      showNotes: false,
      hasNotes:
        this.session_template.notes && this.session_template.notes !== "<p></p>"
    };
  },
  computed: {
    originalTemplate() {
      return this.initializeSessionTemplateForm(this.sessionTemplateState);
    }
  },
  methods: {
    initializeSessionTemplateForm(session_template) {
      return {
        title: session_template.title,
        classroom: session_template.classroom,
        assessors: session_template.assessors,
        learners: session_template.learners,
        objectives: session_template.objectives,
        notes: session_template.notes,
        errors: {}
      };
    },
    setNotesHTML() {
      this.$refs.templateNotes.innerHTML = this.sessionTemplateState.notes;
    },
    toggleNotes() {
      this.showNotes = !this.showNotes;
    },
    fetchLearners(input) {
      if (this.sessionTemplateState.classroom !== input) {
        this.sessionTemplateForm.learners = [];
      }
      SessionTemplatesService.fetchLearners(input, this);
    },
    getAssessors() {
      return this.sessionTemplateState.assessors
        .map(assessor => assessor.label)
        .join(", ");
    }
  },
  mounted() {
    this.setNotesHTML();
    this.fetchLearners(this.sessionTemplateForm.classroom);
  }
});
